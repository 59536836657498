<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="pages !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0">
        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="pages"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found!"
        >
          <template #cell(edit)="data">
            <b-link class="cursor-pointer" :to="`/apps/dynamic-pages/edit/${data.item.id}`">
              <feather-icon icon="EyeIcon" size="20" class="text-primary"/>
            </b-link>
          </template>

          <template #cell(delete)="data">
            <span class="cursor-pointer" @click="deletePage(data.item.id)">
              <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
            </span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup,BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {PageDeleteRequest, PageGetListRequest} from "@/libs/Api/Page";

export default {
  title: 'Tickets List',
  name: 'pages',
  data() {
    return {
      overlay:false,
      pages: null,
      totalCount: null,
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      CategoryName:'',
      TicketType:0,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'title',
          label: 'title'
        },
        {
          key: 'seourl',
          label: 'Url'
        },
        {
          key: 'edit',
          label: 'edit'
        },
        {
          key: 'delete',
          label: 'delete'
        },
      ],
      pageNumber: 1,
      search: '',
      SelectedTicket: null,
    }
  },
  async created() {
    await this.getAllPages()
  },
  watch: {
    search: function () {
      this.getAllPages()
    },
    currentPage: function () {
      this.getAllPages()
    },
    TicketType:function (){
      this.getAllPages()
    }
  },
  methods: {
    async getAllPages() {
      let _this = this;
      _this.overlay = true;

      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
      }

      let pageGetListRequest = new PageGetListRequest(_this);
      pageGetListRequest.setParams(data);
      await pageGetListRequest.fetch(function (content) {
        _this.overlay = false;
        _this.pages = content.data;
        _this.totalCount = content.total;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async deletePage(id) {
      let _this = this;
      _this.overlay = true;

      let data = {
        id:id
      }
      let pageDeleteRequest = new PageDeleteRequest(_this);
      pageDeleteRequest.setParams(data);
      await pageDeleteRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getAllPages();
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
}
</script>

<style scoped>

</style>
